define(['lodash',
    'prop-types',
    'componentsCore',
    'warmupUtils',
    'image-client-api',
    'santa-components'],
function (_,
          PropTypes,
          componentsCore,
          warmupUtilsLib,
          imageClientLib,
          santaComponents) {
    'use strict';
    const imageElements = santaComponents.components.imageCommon.imageElements;

    /**
     * @class components.bgImage
     * @extends {core.skinBasedComp}
     */
    return {
        displayName: 'bgImage',
        mixins: [componentsCore.mixins.skinBasedComp],
        propTypes: {
            cssFiltersSupported: santaComponents.santaTypesDefinitions.BrowserFlags.cssFiltersSupported.isRequired,
            compData: PropTypes.object.isRequired,
            shouldRenderSrc: PropTypes.bool,
            imageUrlPreMeasureParams: PropTypes.object,
            currentUrl: santaComponents.santaTypesDefinitions.currentUrl,
            getMediaFullStaticUrl: santaComponents.santaTypesDefinitions.ServiceTopology.getMediaFullStaticUrl.isRequired,
            filterEffect: PropTypes.object,
            style: PropTypes.object,
            mediaDimensions: PropTypes.object,
            imageData: PropTypes.object,
            fittingType: PropTypes.string,
            alignType: PropTypes.string,
            'data-type': PropTypes.string
        },

        extendWithFilterData(refData) {
            let filterId, filterComp;
            const validFilterName = imageElements.getValidFilterName(this.props.filterEffect);

            if (validFilterName) {
                filterId = `${this.props.id}_${validFilterName}`;
                filterComp = imageElements.getFilterComponent(filterId, validFilterName, _.omit(this.props.filterEffect, 'effectType'));

                if (this.props.cssFiltersSupported) {
                    refData[''].addChildren = filterComp;
                    _.assign(refData.style, imageElements.getCssStyleForFilterUse(filterId));
                }
            }
        },

        getImageTransform() {
            const {imageData, getMediaFullStaticUrl, currentUrl, mediaDimensions} = this.props;
            const imageUrlPreMeasureParams = _.pick(this.props.imageUrlPreMeasureParams, [
                'width', 'height', 'filters', 'fittingType', 'imageCss'
            ]);

            const imageInfo = {
                imageData,
                containerWidth: _.defaultTo(imageUrlPreMeasureParams.width, mediaDimensions.width),
                containerHeight: _.defaultTo(imageUrlPreMeasureParams.height, mediaDimensions.height),
                displayMode: _.defaultTo(imageUrlPreMeasureParams.fittingType, imageClientLib.fittingTypes.TILE),
                quality: {quality: 85},
                filters: imageUrlPreMeasureParams.filters
            };

            return warmupUtilsLib.imageUtils.getImageComputedProperties(imageClientLib, imageInfo, getMediaFullStaticUrl, currentUrl, 1, 'bg');
        },


        getSkinProperties() {
            const style = {
                position: 'absolute',
                width: '100%',
                height: '100%'
            };

            if (_.isNumber(this.props.compData.opacity)) {
                style.opacity = this.props.compData.opacity;
            }

            if (this.props.shouldRenderSrc) {
                const imageTransformData = this.getImageTransform();
                style.backgroundImage = `url(${imageTransformData.uri})`;
                style.height = this.props.style.height;
                _.assign(style, imageTransformData.css.container);
            }
            const imageDataForLayout = _.pick(this.props.imageData, ['width', 'height', 'uri', 'name']);
            const imageLayoutProps = _.pick(this.props, ['alignType', 'fittingType']);
            imageLayoutProps.imageData = imageDataForLayout;
            const refData = {
                '': {
                    style,
                    'data-tiled-image-info': JSON.stringify(imageLayoutProps)
                }
            };

            this.extendWithFilterData(refData);

            return refData;
        }
    };
});
